import { useStore } from "@nanostores/react";
import { ProjectPages } from "src/context/server";
import {CurrProjectPage} from "@context/homePages";
import type {ProjectPage} from "src/context/server";
import type { HTMLAttributes } from 'astro/types'
import TestShaderThing from "@components/ImageCanvas2";

type projectProps = {
    attr: keyof ProjectPage
} & HTMLAttributes<'div'>

type imageProps = {
    className?: string
} & HTMLAttributes<'div'>

export const ProjectPageAttribute = (props: projectProps) => {
    const $currPage = useStore(CurrProjectPage);
    return (
        <>
            {ProjectPages[$currPage][props.attr]}
        </>
    )
}

export const ProjectPageImage = () => {
    const $currPage = useStore(CurrProjectPage);
    return (
        <img className="hidden" src={ProjectPages[$currPage].image}></img>
    )
}

export const ProjectPageImageCanvas = (props: imageProps) => {
    const $currPage = useStore(CurrProjectPage);
    return (
        <a href={"/platforms/"+ProjectPages[$currPage].platformLink} className={props.className}>
        <div className="h-full w-full">
        <TestShaderThing />
            </div>
            <ProjectPageImage />
        </a>
    )
}

export function ProjectPageNumber(props: any) {
    // read the store value with the `useStore` hook
    const $currPage = useStore(CurrProjectPage);
    // write to the imported store using `.set`
    return (
        <>
            P/0{$currPage+1}
        </>
    )
  }
